import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { FundSeriesService } from '../services/FundSeriesService.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit {

  public gridOptions: GridOptions;
  columnDefs; defaultColDef; rowSelection; domLayout; paginationPageSize;
  rowData: any;
  AllData: any;
  showLoading: boolean = false;
  gridApi: any;
  gridColumnApi:any;
  pageIndex: number;
  pageSize: number
  TotalPages: number;
  keyword: string;
  category: string;
  showFilter:boolean=false;
  searchBy:string="A";

  constructor(private _seriesMasterService: FundSeriesService, private router: Router, private _activateRoute: ActivatedRoute) {

    this.showLoading = false;
    this.category = this._activateRoute.snapshot.paramMap.get('cname');

    // initialize the grid with desired settings
    this.gridOptions = {
      suppressMovableColumns: true,
      suppressDragLeaveHidesColumns: true,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      enableCellTextSelection: true
    };

    // define the grid columns
    if(this.category != '')
      {
        this.category = this.category.toLowerCase();

        if(this.category == 'index')
        {
          this.columnDefs = [
            // { headerName: 'Index ID', field: 'indexId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 330, minWidth: 280, maxWidth: 450,suppressSizeToFit: false },
            { headerName: 'Index Name', field: 'indexName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter'},
            { headerName: 'Currency', field: 'currency', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
            { headerName: 'Symbol', field: 'symbol', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
            { headerName: 'Exchange', field: 'exchange', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
            { headerName: 'Open', field: 'open', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
            { headerName: 'High', field: 'high', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
            { headerName: 'Low', field: 'low', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
            { headerName: 'Close', field: 'close', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
            { headerName: 'Volume', field: 'volume', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' }
            //{ headerName: 'Created On', field: 'createdOn', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
            //{ headerName: 'Update On', field: 'updatedOn', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true, type: 'rightAligned' }
          ]
        }
        else if(this.category == 'stock')
        {
          this.columnDefs = [
            // { headerName: 'Security Id', field: 'securityId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            { headerName: 'Security Name', field: 'securityName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 280, minWidth: 250, maxWidth: 310,suppressSizeToFit: false },
            // { headerName: 'Company Id', field: 'companyId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            { headerName: 'Company Name', field: 'companyName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            { headerName: 'Ticker', field: 'ticker', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 130, minWidth: 110, maxWidth: 150,suppressSizeToFit: false },
            // { headerName: 'Exchange Id', field: 'exchangeId', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            { headerName: 'Exchange Code', field: 'exchangeCode', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 170, minWidth: 150, maxWidth: 190,suppressSizeToFit: false },
            { headerName: 'Security Type', field: 'securityType', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            //{ headerName: 'Security Type 2', field: 'securityType2', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'FIGI', field: 'figi', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            // { headerName: 'Figi Composite', field: 'figiComposite', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            // { headerName: 'Share Class', field: 'shareClass', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            //{ headerName: 'Security Description', field: 'securityDescription', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            //{ headerName: 'Security Description Short', field: 'securityDescriptionShort', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            //{ headerName: 'Region', field: 'region', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            //{ headerName: 'Country Id', field: 'countryId', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'Country Name', field: 'countryName', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            // { headerName: 'CIK ID', field: 'cikId', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'ISIN', field: 'isin', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false }
            // { headerName: 'SEDOL', field: 'sedol', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            // { headerName: 'CUSIP', field: 'cusip', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false }
          ]
        }
        else if(this.category == 'bond')
        {
          this.columnDefs = [
            { headerName: 'Security Id', field: 'securityId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            { headerName: 'Security Name', field: 'securityName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 200, minWidth: 170, maxWidth: 230,suppressSizeToFit: false },
            { headerName: 'Company Id', field: 'companyId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            { headerName: 'Company Name', field: 'companyName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            { headerName: 'Ticker', field: 'ticker', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 130, minWidth: 110, maxWidth: 150,suppressSizeToFit: false },
            { headerName: 'Exchange Id', field: 'exchangeId', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            { headerName: 'Exchange Code', field: 'exchangeCode', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 170, minWidth: 150, maxWidth: 190,suppressSizeToFit: false },
            { headerName: 'Security Type', field: 'securityType', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'Security Type 2', field: 'securityType2', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'FIGI', field: 'figi', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'Figi Composite', field: 'figiComposite', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'Share Class', field: 'shareClass', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'Security Description', field: 'securityDescription', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'Security Description Short', field: 'securityDescriptionShort', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'Region', field: 'region', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'Country Id', field: 'countryId', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'Country Name', field: 'countryName', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'CIK ID', field: 'cikId', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'ISIN', field: 'isin', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'SEDOL', field: 'sedol', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false },
            { headerName: 'CUSIP', field: 'cusip', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,  width: 160, minWidth: 140, maxWidth: 180,suppressSizeToFit: false }
          ]
        }
        else if(this.category == 'mutual fund')
        {
          this.columnDefs = [
            // // { headerName: 'Fund Class Id', field: 'fundClassId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            // { headerName: 'Fund Class Name', field: 'fundClassName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 400, minWidth: 380, maxWidth: 420,suppressSizeToFit: false },
            // // { headerName: 'Fund Series Id', field: 'fundSeriesId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            // { headerName: 'Fund Series Name', field: 'fundSeriesName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 450, minWidth: 430, maxWidth: 470,suppressSizeToFit: false },
            // { headerName: 'Ticker', field: 'ticker', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 380, minWidth: 360, maxWidth: 400,suppressSizeToFit: false }

            { headerName: 'Fund Class Name', field: 'fC_ClassName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 360, minWidth: 340, maxWidth: 380,suppressSizeToFit: false,

              getQuickFilterText: params => {
                return params.value;
              }
            },
            { headerName: 'Ticker', field: 'ticker', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 120, minWidth: 100, maxWidth: 140,suppressSizeToFit: false },
            { headerName: 'CIK ID', field: 'fS_CIKId', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 120, minWidth: 100, maxWidth: 140,suppressSizeToFit: false },
            { headerName: 'Exchange', field: 'exchangeCode', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 140, minWidth: 120, maxWidth: 160,suppressSizeToFit: false },
            { headerName: 'Category 1', field: 'category1', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 180, minWidth: 160, maxWidth: 200,suppressSizeToFit: false },
            { headerName: 'Category 2', field: 'category2', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 180, minWidth: 160, maxWidth: 200,suppressSizeToFit: false },
            { headerName: 'Category 3', field: 'category3', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 180, minWidth: 160, maxWidth: 200,suppressSizeToFit: false },
            { headerName: 'Total Assets', field: 'fS_TotalAssets', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 170, minWidth: 150, maxWidth: 190,suppressSizeToFit: false },
            { headerName: 'Total Liabilities', field: 'fS_TotalLiabilities', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 180, minWidth: 160, maxWidth: 200,suppressSizeToFit: false },
            { headerName: 'Market Cap', field: 'marketCap', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 180, minWidth: 160, maxWidth: 200,suppressSizeToFit: false },
            { headerName: 'Reporting Date', field: 'fS_ReportingDate',
                cellRenderer: (params) => {
                  return params.value ? (this.toShortFormat(new Date(params.value))) : '';
              },
              filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 170, minWidth: 150, maxWidth: 190,suppressSizeToFit: false }


          ]
        }
        else if(this.category == 'etf')
        {
          this.columnDefs = [
            // { headerName: 'Fund Class Id', field: 'fundClassId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            // { headerName: 'Fund Series Id', field: 'fundSeriesId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 280, minWidth: 260, maxWidth: 300,suppressSizeToFit: false },
            //{ headerName: 'Fund Series Name', field: 'fundSeriesName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 450, minWidth: 430, maxWidth: 470,suppressSizeToFit: false },
            { headerName: 'Fund Class Name', field: 'fC_ClassName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 370, minWidth: 350, maxWidth: 390,suppressSizeToFit: false,
              getQuickFilterText: params => {
                return params.value;
              }
            },
            { headerName: 'Ticker', field: 'ticker', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 110, minWidth: 90, maxWidth: 130,suppressSizeToFit: false },
            { headerName: 'CIK ID', field: 'fS_CIKId', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 110, minWidth: 90, maxWidth: 130,suppressSizeToFit: false },
            { headerName: 'Exchange', field: 'exchangeCode', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 140, minWidth: 120, maxWidth: 160,suppressSizeToFit: false },
            { headerName: 'Category 1', field: 'category1', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 150, minWidth: 140, maxWidth: 160,suppressSizeToFit: false },
            { headerName: 'Category 2', field: 'category2', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 150, minWidth: 140, maxWidth: 160,suppressSizeToFit: false },
            { headerName: 'Category 3', field: 'category3', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 150, minWidth: 140, maxWidth: 160,suppressSizeToFit: false },
            { headerName: 'Total Assets', field: 'fS_TotalAssets', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 180, minWidth: 160, maxWidth: 200,suppressSizeToFit: false,
                // cellRenderer: (params) => {
                //   return params.value ? (this.millionFormatter.transform(params.value)) : '';
                // },
            },
            { headerName: 'Total Liabilities', field: 'fS_TotalLiabilities', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 180, minWidth: 160, maxWidth: 200,suppressSizeToFit: false },
            { headerName: 'Market Cap', field: 'marketCap', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 180, minWidth: 160, maxWidth: 200,suppressSizeToFit: false },
            { headerName: 'Reporting Date', field: 'fS_ReportingDate',
                cellRenderer: (params) => {
                  return params.value ? (this.toShortFormat(new Date(params.value))) : '';
              },
              filter: 'agTextColumnFilter', sortable: true, unSortIcon: true,  width: 180, minWidth: 160, maxWidth: 200,suppressSizeToFit: false }
          ]
        }
         else
        {
          //Default one is Index
          this.columnDefs = [
            // { headerName: 'Index ID', field: 'indexId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter', width: 330, minWidth: 280, maxWidth: 450,suppressSizeToFit: false },
            { headerName: 'Index Name', field: 'indexName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
            { headerName: 'Region', field: 'region', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' }
            // { headerName: 'Created On', field: 'createdOn', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
            // { headerName: 'Update On', field: 'updatedOn', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true, type: 'rightAligned' }
          ]
        }
        }


    this.pageIndex = 1;
    this.pageSize = 100;


    // make request to server to load the classInfo data
    this.getSeriesMasterData();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    params.api.doLayout();
  }

  ngOnInit(): void {
  }

  getSeriesMasterData() {
    if(this.category != '')
    {
    this.showLoading = true;
      // this._seriesMasterService.getFundSeriesMasters(this.pageIndex, this.pageSize).subscribe(data => {
      this._seriesMasterService.searchFundSeriesMastersByCategory(this.category, this.pageIndex, this.pageSize, this.searchBy).subscribe(data => {
        this.AllData = this.rowData = data;
        if(this.rowData.length>0)
        this.TotalPages = data[0].totalPages;
        this.showLoading = false;
        },
        error => {
          console.log("Something went wrong " + error);
          this.showLoading = false;
        }
      )
    }
  }

  searchSeriesMasterData() {
    var searchKey = this.keyword;
    if (this.keyword.trim() != "") {
      this.rowData = this.rowData.filter(function (item) {
        if (item.cikId == searchKey || item.seriesId == searchKey || item.seriesLei.toLowerCase().indexOf(searchKey.toLowerCase()) != -1 || item.seriesName.toLowerCase().indexOf(searchKey.toLowerCase()) != -1) {
          return item;
        }
      });
    }
    else {
      this.rowData = this.AllData;
    }
  }

  onCellClicked($event:any) {
    if(this.category !=''){
      this.category =this.category.toLowerCase();

        if (this.category == 'index' && $event.data.indexID != '') {
          if ($event.colDef.field == 'indexName')
              this.router.navigate(['/fund-details/index/', $event.data.indexID]);
        }
        else  if (this.category == 'stock' && $event.data.securityId != '') {
          if ($event.colDef.field == 'securityName')
            this.router.navigate(['/fund-details/stock/', $event.data.securityId]);
        }
        else  if (this.category == 'bond' && $event.data.securityId != '') {
          if ($event.colDef.field == 'securityName')
            this.router.navigate(['/fund-details/bond/', $event.data.securityId]);
        }
        else  if (this.category == 'mutual fund' && $event.data.fundClassId != '') {
          if ($event.colDef.field == 'fC_ClassName')
              //this.router.navigate(['/fund-details/mutual fund/', $event.data.fundClassId]);
              this.router.navigate(['/fund-details/mutual fund/', $event.data.fC_FundSeriesId]);//$event.data.mfId,
        }
        else  if (this.category == 'etf' && $event.data.fundClassId != '') {
          if ($event.colDef.field == 'fC_ClassName')
              //this.router.navigate(['/fund-details/etf/', $event.data.fC_FundClassInfoId,$event.data.fC_FundSeriesId]);
              this.router.navigate(['/fund-details/etf/', $event.data.fC_FundSeriesId]);//$event.data.etfId,
        }
        else {
          alert('ID is empty for ' + this.category);
        }
    }
  }

  ShowFirstPage() {
    this.pageIndex = 1;
    this.getSeriesMasterData();
  }

  ShowPreviousPage() {
    this.pageIndex--;
    this.getSeriesMasterData();
  }

  ShowNextPage() {
    this.pageIndex++;
    this.getSeriesMasterData();
  }

  ShowLastPage() {
    this.pageIndex = this.TotalPages;
    this.getSeriesMasterData();
  }

  clickFilterLinks() {
    this.showFilter=!this.showFilter;
  }

  public changeSearchBy(letter){
    this.searchBy = letter;
    this.getSeriesMasterData();
  }

  toShortFormat(date) {

    let monthNames =["Jan","Feb","Mar","Apr",
                      "May","Jun","Jul","Aug",
                      "Sep", "Oct","Nov","Dec"];

    let day = date.getDate();

    let monthIndex = date.getMonth();
    let monthName = monthNames[monthIndex];

    let year = date.getFullYear();

    return `${day}-${monthName}-${year}`;
}

}
