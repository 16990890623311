<nav class="navbar navbar-expand-md navbar-light bg-light">
    <a href="#" class="navbar-brand">RecipeBook</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsing-nav-bar" aria-controls="collapsing-nav-bar"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsing-nav-bar">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <a href="#" class="nav-link">Recipes</a>
            </li>
            <li class="nav-item">
                <a href="#" class="nav-link">Shopping list</a>
            </li>
        </ul>
        <ul class="navbar-nav">
            <li class="nav-item dropdown">
                <a href="#" class="nav-link dropdown-toggle" id="dropdownMenu" data-toggle="dropdown" 
                aria-haspopup="true" aria-expanded="false">
                    Manage
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenu">
                    <a href="#" class="dropdown-item">Save</a>
                    <a href="#" class="dropdown-item">Fetch</a>
                </div>
            </li>
        </ul>
    </div>
</nav>