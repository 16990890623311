<div class="row">
    <!-- <div class="col-md-12 text-right">
        <div class="welcomeMsg">
            Welcome {{userName}}, <a href>Logout</a>
        </div>
    </div> -->

    <div class="col-md-12" *ngIf="userName">
        <div class="navbar navbar-expand-md navbar-dark bg-dark bg-darkCustom mb-4" role="navigation">
            <!-- <a class="navbar-brand" href="#">Bootstrap 4 NavBar</a> -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/quick-search"><i class="fa fa-home fa-2x"></i></a>
                </li>
                  <!-- <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="newsDD" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">News</a>
                        <ul class="dropdown-menu" aria-labelledby="newsDD">
                            <li class="dropdown-item" href="#"><a>News 1</a></li>
                            <li class="dropdown-item" href="#"><a>News 2</a></li>
                            <li class="dropdown-item" href="#"><a>News 3</a></li>
                            <li class="dropdown-item" href="#"><a>News 4</a></li>
                            <li class="dropdown-item" href="#"><a>News 5</a></li>
                            <li class="dropdown-item" href="#"><a>News 6</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="" target="_blank">Dashboard</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Research</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="ScreenerDD" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">Screener</a>
                        <ul class="dropdown-menu" aria-labelledby="ScreenerDD">
                            <li class="dropdown-item" href="#"><a>Screener 1</a></li>
                            <li class="dropdown-item" href="#"><a>Screener 2</a></li>
                            <li class="dropdown-item" href="#"><a>Screener 3</a></li>
                            <li class="dropdown-item" href="#"><a>Screener 4</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="CompaniesDD" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">Companies</a>
                        <ul class="dropdown-menu" aria-labelledby="CompaniesDD">
                            <li class="dropdown-item" href="#"><a>Companies 1</a></li>
                            <li class="dropdown-item" href="#"><a>Companies 2</a></li>
                            <li class="dropdown-item" href="#"><a>Companies 3</a></li>
                            <li class="dropdown-item" href="#"><a>Companies 4</a></li>
                            <li class="dropdown-item" href="#"><a>Companies 5</a></li>
                            <li class="dropdown-item" href="#"><a>Companies 6</a></li>
                            <li class="dropdown-item" href="#"><a>Companies 7</a></li>
                            <li class="dropdown-item" href="#"><a>Companies 8</a></li>
                            <li class="dropdown-item" href="#"><a>Companies 9</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="MarketsDealsDD" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Markets & Deals</a>
                        <ul class="dropdown-menu" aria-labelledby="MarketsDealsDD">
                            <li class="dropdown-item" href="#"><a>Markets & Deals 1</a></li>
                            <li class="dropdown-item" href="#"><a>Markets & Deals 2</a></li>
                            <li class="dropdown-item" href="#"><a>Markets & Deals 3</a></li>
                            <li class="dropdown-item" href="#"><a>Markets & Deals 4</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="IndustriesDD" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Industries</a>
                        <ul class="dropdown-menu" aria-labelledby="IndustriesDD">
                            <li class="dropdown-item" href="#"><a>Industries 1</a></li>
                            <li class="dropdown-item" href="#"><a>Industries 2</a></li>
                            <li class="dropdown-item" href="#"><a>Industries 3</a></li>
                            <li class="dropdown-item" href="#"><a>Industries 4</a></li>
                            <li class="dropdown-item" href="#"><a>Industries 5</a></li>
                            <li class="dropdown-item" href="#"><a>Industries 6</a></li>
                            <li class="dropdown-item" href="#"><a>Industries 7</a></li>
                            <li class="dropdown-item" href="#"><a>Industries 8</a></li>

                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="GeographiesDD" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Geographies</a>
                        <ul class="dropdown-menu" aria-labelledby="IndustriesDD">
                            <li class="dropdown-item" href="#"><a>Geographies 1</a></li>
                            <li class="dropdown-item" href="#"><a>Geographies 2</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="ESGDD" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">ESG</a>
                        <ul class="dropdown-menu" aria-labelledby="ESGDD">
                            <li class="dropdown-item" href="#"><a>ESG 1</a></li>
                            <li class="dropdown-item" href="#"><a>ESG 2</a></li>
                            <li class="dropdown-item" href="#"><a>ESG 3</a></li>
                            <li class="dropdown-item" href="#"><a>ESG 4</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="ToolsDD" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">Tools</a>
                        <ul class="dropdown-menu" aria-labelledby="ToolsDD">
                            <li class="dropdown-item" href="#"><a>Tools 1</a></li>
                            <li class="dropdown-item" href="#"><a>Tools 2</a></li>
                            <li class="dropdown-item" href="#"><a>Tools 3</a></li>
                        </ul>
                    </li> -->

                </ul>
                <span style="color:#311A20">Welcome {{userName}}, <a (click)="logoutUser()" href style="color:darkblue">Logout</a></span>
                <!-- <form class="form-inline mt-2 mt-md-0">
                <input class="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search">
                <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
            </form> -->
            </div>
        </div>
    </div>

    <!-- <div class="col-md-12 text-center">
        <div class="secLogo">
            <a href=""><img src="../assets/img/logo.jpg" width="250" alt="Security Master Logo"
                    title="Security Master" /></a>
        </div>
        !-- <nav>
            <a class="button" routerLink="/quick-search" routerLinkActive="activebutton">Quick Search</a>
            <a class="button" routerLink="/search-result" routerLinkActive="activebutton">Search Result</a>
        </nav> --
    </div> -->
</div>
<div class="mb100">
    <router-outlet></router-outlet>
</div>
<footer class="footer text-center">
    <span class="text-muted">Developed & Maintained by <img
            src="https://static.wixstatic.com/media/b2f804_81a332489bc74517887294791e4ff207.png/v1/fill/w_139,h_79,al_c,q_85,usm_0.66_1.00_0.01/b2f804_81a332489bc74517887294791e4ff207.webp"
            width="50"></span>
</footer>
