<div class="container-fluid">

    <div class="row">
        <div class="col-md-2 line">
            Currency
            <select class="browser-default custom-select" style="width:160px;vertical-align:middle;">
                <option selected>U.S. Dollar (USD)</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>
        </div>
        <div class="col-md-10" style="margin-top:5px;">
            <b><i>FILTERS:</i></b>
            <div class="filterLink">
                <b (click)="clickFilterLinks()">Summary</b>
                <sub>View Security</sub>
            </div>
            <div class="filterLink">
                <b (click)="clickFilterLinks()">Institutional</b>
                <sub>Owner Type</sub>
            </div>
            <div class="filterLink">
                <b (click)="clickFilterLinks()">All</b>
                <sub>Long/Short Position</sub>
            </div>
            <div class="filterLink">
                <b (click)="clickFilterLinks()">All</b>
                <sub>Turnover Type</sub>
            </div>
            <div class="filterLink">
                <b (click)="clickFilterLinks()">All</b>
                <sub>Positions</sub>
            </div>
            <div class="filterLink">
                <b (click)="clickFilterLinks()">Latest fillings</b>
                <sub>Position Date</sub>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row" style="font-weight: 500;" *ngIf="showFilter">
        <div class="col-md-4 line">
            <div class="row">
                <div class="col-md-3  mb-2 vm">View Security</div>
                <div class="col md-9  mb-2"> <select class="browser-default custom-select"
                        style="width:340px;vertical-align:middle;">
                        <option selected>summary</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select> </div>
            </div>
            <div class="row">
                <div class="col-md-3 mb-2 vm">Positions</div>
                <div class="col md-9 mb-2">
                    <select class="browser-default custom-select" style="width:340px;vertical-align:middle;">
                        <option selected>Decreased, Increased, New, Sold Out, Unchanged</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div>
            </div>
           <div class="row">
                <div class="col-md-3 mb-2 vm">Turnover Type</div>
                <div class="col md-9 mb-2">
                    <select class="browser-default custom-select" style="width:340px;vertical-align:middle;">
                        <option selected>All</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div>
            </div>
        </div>
        <!--End of Column 1-->
        <div class="col-md-4 line">
            <div class="row">
                <div class="col-md-4 mb-2 vm">Owner Type</div>
                <div class="col md-8 mb-2">
                    <select class="browser-default custom-select" style="width:300px;vertical-align:middle;">
                        <option selected>institutional</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 mb-2 vm">Posiiton Date</div>
                <div class="col md-8 mb-2">
                    <select class="browser-default custom-select" style="width:300px;vertical-align:middle;">
                        <option selected>Latest Filings</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 mb-2 vm">Market Cap Type</div>
                <div class="col md-8 mb-2">
                    <select class="browser-default custom-select" style="width:300px;vertical-align:middle;">
                        <option selected>All</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div>
            </div>
        </div>
        <!--End of Column 2-->
        <div class="col-md-4 mb-2">
            <div class="row">
                <div class="col-md-5 mb-2 vm">Long/Short Position Type</div>
                <div class="col md-7 mb-2">
                    <select class="browser-default custom-select" style="width:250px;vertical-align:middle;">
                        <option selected>All</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 mb-2 vm">Style Type</div>
                <div class="col md-7 mb-2">
                    <select class="browser-default custom-select" style="width:250px;vertical-align:middle;">
                        <option selected>All</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 mb-2 vm">Keyword</div>
                <div class="col md-7 mb-2">
                    <input type="text" style="width:250px;vertical-align:middle;border:solid 1px #ced4da;border-radius:4px;">
                </div>
            </div>
        </div>
        <!--End of Column 3-->
    </div>

    <div class="row">
        <div class="col-md-12 mt-4">
            <h2 class="catHeader">
                {{category | uppercase}}
            </h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <!-- <ag-grid-angular style="width: 100%; height: 500px;" class="ag-theme-alpine" [rowData]="rowData"
                [columnDefs]="columnDefs" [pagination]="false" [suppressScrollOnNewData]=true>
            </ag-grid-angular> -->
            <div id="fundSeriesMasterGrid">
              <div *ngIf="category.toLowerCase() != 'index'" class="text-left" style="width:90%; font-weight: bold;font-size:20px;padding:20px 0;">
                <a (click)="changeSearchBy('A')">A</a>
                <a (click)="changeSearchBy('B')">B</a>
                <a (click)="changeSearchBy('C')">C</a>
                <a (click)="changeSearchBy('D')">D</a>
                <a (click)="changeSearchBy('E')">E</a>
                <a (click)="changeSearchBy('F')">F</a>
                <a (click)="changeSearchBy('G')">G</a>
                <a (click)="changeSearchBy('H')">H</a>
                <a (click)="changeSearchBy('I')">I</a>
                <a (click)="changeSearchBy('J')">J</a>
                <a (click)="changeSearchBy('K')">K</a>
                <a (click)="changeSearchBy('L')">L</a>
                <a (click)="changeSearchBy('M')">M</a>
                <a (click)="changeSearchBy('N')">N</a>
                <a (click)="changeSearchBy('O')">O</a>
                <a (click)="changeSearchBy('P')">P</a>
                <a (click)="changeSearchBy('Q')">Q</a>
                <a (click)="changeSearchBy('R')">R</a>
                <a (click)="changeSearchBy('S')">S</a>
                <a (click)="changeSearchBy('T')">T</a>
                <a (click)="changeSearchBy('U')">U</a>
                <a (click)="changeSearchBy('V')">V</a>
                <a (click)="changeSearchBy('W')">W</a>
                <a (click)="changeSearchBy('X')">X</a>
                <a (click)="changeSearchBy('Y')">Y</a>
                <a (click)="changeSearchBy('Z')">Z</a>
              </div>


              <ag-grid-angular [rowData]="rowData"
              [columnDefs]="columnDefs"
              style="width: 100%; height: 500px;table-layout: fixed;"
              [defaultColDef]="defaultColDef"
              [rowSelection]="rowSelection"
              [gridOptions]="gridOptions"
              [domLayout]="domLayout"
              (cellClicked)="onCellClicked($event)"
              class="ag-theme-alpine">
              </ag-grid-angular>


              <ng-template #fundSeriesTemplate>

              </ng-template>
                <div style="width:100%;">
                  <div style="float:left;width:50%;">
                    &nbsp;
                  </div>
                  <div style="width:50%;float:left;text-align:right;">
                    <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==1 || this.showLoading==true" (click)="ShowFirstPage();" value="<< " title="First page" />
                    <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==1 || this.showLoading==true" (click)="ShowPreviousPage();" value="< " title="Previous page" />
                    <input type="text" disabled style="margin:5px;padding:10px;width:50px;" value="{{this.pageIndex}} of {{this.TotalPages}}" />
                    <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==this.TotalPages || this.showLoading==true" (click)="ShowNextPage();" value="> " title="Next page" />
                    <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==this.TotalPages || this.showLoading==true" (click)="ShowLastPage();" value=">> " title="Last page" />
                  </div>

                </div>
              </div>
        </div>
    </div>

</div>





<!--[defaultColDef]="defaultColDef"
    [rowSelection]="rowSelection"
     [gridOptions]="gridOptions"
    [domLayout]="domLayout"
    [paginationPageSize]="paginationPageSize" -->
