<div class="login-container">
  <div class="login-cards">
    <div class="login-header text-center">
      <div class="login-overlay"></div>
      <div class="login-relative">
        <h2 class="login-heading">Welcome</h2>
        <p class="login-desc">Sign in to continue to Security Master Portal</p>
        <p>&nbsp;</p>
        <div class="login-logo"><img src="../assets/img/logo.jpg"  style="border-radius: 10px;" alt="" /></div>
      </div>
    </div>
    <div class="login-body">
        <button type="submit" class="cbtn-primary cbtn-block" (click)="Login()">Login</button>
    </div>
  </div>
</div>
