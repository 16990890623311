import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { AgGridModule} from 'ag-grid-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ClassInfoComponent } from './class-info/class-info.component';
import { FundHoldingsComponent } from './fund-holdings/fund-holdings.component';
import { FundSeriesMasterComponent } from './fund-series-master/fund-series-master.component';
import { FormsModule} from '@angular/forms';
import { QuickSearchComponent } from './quick-search/quick-search.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FundDetailsComponent } from './fund-details/fund-details.component';

import { environment } from 'src/environments/environment';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalService} from "@azure/msal-angular";
import { MsalUserService } from './services/msaluser.service';
import { HighchartsChartModule } from 'highcharts-angular';
import { FundPerformanceChartComponent } from './fund-performance-chart/fund-performance-chart.component';
import { FundHoldingDetailsComponent } from './fund-holding-details/fund-holding-details.component';
import { DecodeHtmlStringPipe } from './pipes/decode-html-string.pipe';
import { MillionPipe } from './pipes/million.pipe';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { Error404Component } from './error404/error404.component';

export const protectedResourceMap: any =
  [
    [environment.baseUrl, environment.scopeUri]
  ];

  // export function MSALInstanceFactory(): IPublicClientApplication{
  //   return new PublicClientApplication({
  //     auth:{
  //       clientId:'2f479de4-d4d4-4edc-a1a7-d9f20fa554de',
  //       redirectUri:'http://localhost:4200'
  //     }
  //   })
  // }

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ClassInfoComponent,
    FundHoldingsComponent,
    FundSeriesMasterComponent,
    QuickSearchComponent,
    SearchResultComponent,
    NavbarComponent,
    FundDetailsComponent,
    FundPerformanceChartComponent,
    FundHoldingDetailsComponent,
    DecodeHtmlStringPipe,
    MillionPipe,
    LoginComponent,
    LogoutComponent,
    Error404Component
  ],
  imports: [
    BrowserModule,
    HighchartsChartModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    AgGridModule.withComponents([]),
    BrowserAnimationsModule,
    MsalModule.forRoot({
      clientID: environment.uiClienId,
      authority: 'https://login.microsoftonline.com/' + environment.tenantId,
      //cacheLocation: 'localStorage',
      protectedResourceMap: protectedResourceMap,
      redirectUri: environment.redirectUrl
    })
  ],
  providers: [
    HttpClient,
    MsalUserService,
    {
      provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true
    },
    // {
    //   provide: MSAL_Instance,
    //   useFactory:MSALInstanceFactory
    // },
    MsalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
