import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Key } from 'protractor';

@Injectable({ providedIn:'root'})
export class FundSeriesService {

  private _apiURL: string;

  constructor(private http: HttpClient) {
    this._apiURL = environment.config.endpointd.apiUrl;
  }

  getFundSeriesMasters(pageIndex:number,pageSize:number):Observable<any> {
    let httpOptions = this.getHeaders();
    return this.http.get(this._apiURL + "seriesmaster?pageIndex=" + pageIndex + "&pageSize=" + pageSize, { headers: httpOptions });
  }

  searchFundSeriesMasters(keyword:any): Observable<any> {
    let httpOptions = this.getHeaders();
    return this.http.get(this._apiURL + "seriesmaster/searchseries/"+keyword, { headers: httpOptions });
  }

  searchFundSeriesMastersByCategory(category:string, pageIndex: number, pageSize: number, searchBy: string): Observable<any> {
    let httpOptions = this.getHeaders();
    category = category.toLowerCase();

    if(category == 'index')
    {
      return this.http.get(this._apiURL + "index/GetIndecies?PageNumber=" + pageIndex + "&PageSize=" + pageSize, { headers: httpOptions });
      //return this.http.get(this._apiURL + "index/GetIndecies?PageNumber=" + pageIndex + "&PageSize=" + pageSize + "&startswith=" + searchBy, { headers: httpOptions });
    }
    else if(category == 'stock')
    {
      return this.http.get(this._apiURL + "security/uniquestocks?PageNumber=" + pageIndex + "&PageSize=" + pageSize + "&startswith=" + searchBy, { headers: httpOptions });
    }
    else if(category == 'bond')
    {
      return this.http.get(this._apiURL + "tempsecurity/gettempsecuritiesinfo?PageNumber=" + pageIndex + "&PageSize=" + pageSize, { headers: httpOptions });
    }
    else if(category == 'mutual fund')
    {
      //return this.http.get(this._apiURL + "classinfo/GetFundClassesInfo?FundType=MF&PageNumber=" + pageIndex + "&PageSize=" + pageSize, { headers: httpOptions });
      return this.http.get(this._apiURL + "mutualfund/getmutualfunds?PageNumber=" + pageIndex + "&PageSize=" + pageSize + "&startswith=" + searchBy, { headers: httpOptions });
    }
    else if(category == 'etf')
    {
      //return this.http.get(this._apiURL + "classinfo/GetFundClassesInfo?FundType=ETF&PageNumber=" + pageIndex + "&PageSize=" + pageSize, { headers: httpOptions });
      return this.http.get(this._apiURL + "etf/getetfs?PageNumber=" + pageIndex + "&pagesize=" + pageSize + "&startswith=" + searchBy, { headers: httpOptions });
    }
    else
     {
       //Default one is Index
      return this.http.get(this._apiURL + "index/GetIndecies?PageNumber=" + pageIndex + "&PageSize=" + pageSize, { headers: httpOptions });
    }
    }

  private getHeaders() {
    const token = localStorage.getItem("token");
    let header = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      "Access-Control-Allow-Origin" : "*"
    };
    let httpOptions = new HttpHeaders(header);
    return httpOptions;
  }

}

