<p>
  Class-Info Search
</p>

<div id="classInfoGrid" >

<ag-grid-angular
                 [rowData]="rowData"
                 [columnDefs]="columnDefs"
                 style="width: 100%; height: 1000px;"
                 [pagination]="true"
                 [defaultColDef]="defaultColDef"
                 [rowSelection]="rowSelection"
                 [gridOptions]="gridOptions"
                 [domLayout]="domLayout"
                 [paginationPageSize]="paginationPageSize"
                 class="ag-theme-alpine">
  </ag-grid-angular>

</div>

