<div style="padding:0;margin:10px 0;width:100%">
  <ul class="nav nav-pills justify-content-left">
    <li>
      <a class="active" id="seetrend-tab" data-toggle="tab" href="#seetrend">See Trend</a>
    </li>
    <li>
      <a id="holding-details-tab" data-toggle="tab" href="#holdingdetails">Holdings</a>
    </li>
  </ul>
  <div class="tab-content clearfix">
    <div class="tab-pane active" id="seetrend">
      <div class="row">
        <div class="sm-12 col-12">
          <div class="m-30">
            <!-- <h5>Series Id - [{{hId}}] <br> identifierValue = [{{identifierValue}}]</h5> -->
            <h2 style="text-align:center">{{hName}}</h2>
            <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions"
              style="width: 100%; height: 400px;table-layout: fixed;">
            </highcharts-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane" id="holdingdetails">
      <div class="row">
        <div class="sm-12 col-12">
          <div class="m-30">
            <h2 style="text-align:center">{{hName}}</h2>
            <div class="row">
              <div class="sm-12 col-11 m-30">
                <!-- <h2>Holding Details</h2> -->
                <table class="table table-bordered">
                  <tr>
                    <!-- <td class="lgBackground">Holding ID</td>
                    <td class="lgBackground">Series ID</td> -->
                    <td class="lgBackground">Region</td>
                    <td class="lgBackground">CUSIP</td>
                    <td class="lgBackground">Identifier Name</td>
                    <td class="lgBackground">position</td>
                    <td class="lgBackground">marketValue</td>
                  </tr>
                  <tr>
                    <!-- <td class="text-left vBack">{{fundDetails[0]?.holdingId}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.seriesId}}</td> -->
                    <td class="text-left vBack">{{fundDetails[0]?.region}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.cusip}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.identifierName}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.position}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.marketValue}}</td>
                  </tr>
                  <tr>
                    <td class="lgBackground">identifierValue</td>
                    <td class="lgBackground">units</td>
                    <td class="lgBackground">currency</td>
                    <td class="lgBackground">payoffProfile</td>
                    <td class="lgBackground">assetCategory</td>
                  </tr>
                  <tr>
                    <td class="text-left vBack">{{fundDetails[0]?.identifierValue}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.units}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.currency}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.payoffProfile}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.assetCategory}}</td>
                  </tr>
                  <tr>

                    <td class="lgBackground">issuerCategory</td>
                    <td class="lgBackground">investmentCountry</td>
                    <td class="lgBackground">portfolioWeight</td>
                    <td class="lgBackground">totalPages</td>
                    <td class="lgBackground">fundHoldingsDerivativeInfo</td>
                  </tr>
                  <tr>

                    <td class="text-left vBack">{{fundDetails[0]?.issuerCategory}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.investmentCountry}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.portfolioWeight}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.totalPages}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fundHoldingsDerivativeInfo}}</td>
                  </tr>
                  <tr>
                    <td class="lgBackground">isRestrictedSecurity</td>
                    <td class="lgBackground">fairValLevel</td>
                    <td class="lgBackground">firstBoughtDate</td>
                    <td class="lgBackground">fundSeriesMaster</td>
                    <td class="lgBackground">&nbsp;</td>
                  </tr>
                  <tr>
                    <td class="text-left vBack">{{fundDetails[0]?.isRestrictedSecurity}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fairValLevel}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.firstBoughtDate}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fundSeriesMaster}}</td>
                    <td class="text-left vBack">&nbsp;</td>
                  </tr>
                </table>

                <table class="table table-bordered">
                  <tr>
                    <td class="lgBackground">As on Date</td>
                    <td class="lgBackground">Balance</td>
                    <td class="lgBackground">valueInUSD</td>
                    <td class="lgBackground">percentage</td>
                  </tr>
                  <tr *ngFor="let fd of fundDetails; let i = index">
                        <td class="text-left vBack">{{fd?.asOnDate | date:'dd-MMM-yyyy'}}</td>
                        <td class="text-left vBack">{{fd?.balance}}</td>
                        <td class="text-left vBack">{{fd?.valueInUSD}}</td>
                        <td class="text-left vBack">{{fd?.percentage}}</td>
                  </tr>
                </table>
                </div>
                <!-- <div *ngFor="let fd of fundDetails; let i = index">
                  <h2>Holding {{i+1}} Details</h2>
                  <table class="table table-bordered">
                    <tr>
                      <td class="lgBackground">Holding ID</td>
                      <td class="lgBackground">Series ID</td>
                      <td class="lgBackground">Region</td>
                      <td class="lgBackground">CUSIP</td>
                      <td class="lgBackground">Identifier Name</td>
                    </tr>
                    <tr>
                      <td class="text-left vBack">{{fd?.holdingId}}</td>
                      <td class="text-left vBack">{{fd?.seriesId}}</td>
                      <td class="text-left vBack">{{fd?.region}}</td>
                      <td class="text-left vBack">{{fd?.cusip}}</td>
                      <td class="text-left vBack">{{fd?.identifierName}}</td>
                    </tr>
                    <tr>
                      <td class="lgBackground">identifierValue</td>
                      <td class="lgBackground">balance</td>
                      <td class="lgBackground">units</td>
                      <td class="lgBackground">currency</td>
                      <td class="lgBackground">valueInUSD</td>
                    </tr>
                    <tr>
                      <td class="text-left vBack">{{fd?.identifierValue}}</td>
                      <td class="text-left vBack">{{fd?.balance}}</td>
                      <td class="text-left vBack">{{fd?.units}}</td>
                      <td class="text-left vBack">{{fd?.currency}}</td>
                      <td class="text-left vBack">{{fd?.valueInUSD}}</td>
                    </tr>
                    <tr>
                      <td class="lgBackground">percentage</td>
                      <td class="lgBackground">payoffProfile</td>
                      <td class="lgBackground">assetCategory</td>
                      <td class="lgBackground">issuerCategory</td>
                      <td class="lgBackground">investmentCountry</td>
                    </tr>
                    <tr>
                      <td class="text-left vBack">{{fd?.percentage}}</td>
                      <td class="text-left vBack">{{fd?.payoffProfile}}</td>
                      <td class="text-left vBack">{{fd?.assetCategory}}</td>
                      <td class="text-left vBack">{{fd?.issuerCategory}}</td>
                      <td class="text-left vBack">{{fd?.investmentCountry}}</td>
                    </tr>
                    <tr>
                      <td class="lgBackground">isRestrictedSecurity</td>
                      <td class="lgBackground">fairValLevel</td>
                      <td class="lgBackground">firstBoughtDate</td>
                      <td class="lgBackground">position</td>
                      <td class="lgBackground">marketValue</td>
                    </tr>
                    <tr>
                      <td class="text-left vBack">{{fd?.isRestrictedSecurity}}</td>
                      <td class="text-left vBack">{{fd?.fairValLevel}}</td>
                      <td class="text-left vBack">{{fd?.firstBoughtDate}}</td>
                      <td class="text-left vBack">{{fd?.position}}</td>
                      <td class="text-left vBack">{{fd?.marketValue}}</td>
                    </tr>
                    <tr>
                      <td class="lgBackground">portfolioWeight</td>
                      <td class="lgBackground">asOnDate</td>
                      <td class="lgBackground">totalPages</td>
                      <td class="lgBackground">fundHoldingsDerivativeInfo</td>
                      <td class="lgBackground">fundSeriesMaster</td>
                    </tr>
                    <tr>
                      <td class="text-left vBack">{{fd?.portfolioWeight}}</td>
                      <td class="text-left vBack">{{fd?.asOnDate | date:'dd-MMM-yyyy'}}</td>
                      <td class="text-left vBack">{{fd?.totalPages}}</td>
                      <td class="text-left vBack">{{fd?.fundHoldingsDerivativeInfo}}</td>
                      <td class="text-left vBack">{{fd?.fundSeriesMaster}}</td>
                    </tr>
                  </table>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
