import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalUserService } from '../services/msaluser.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public returnUrl: string;
  name:string;

  constructor(private route: ActivatedRoute, private router: Router, private _msalUserService: MsalUserService) {
  }

  ngOnInit(): void {


    //alert("app component ngoninit starts");
    // this.returnUrl = this.route.snapshot.paramMap.get('returnUrl');
     //alert('from login page - this.returnUrl ' + this.returnUrl);
    // if(this.returnUrl  == "gotoQuickSearch")
    // this.router.navigateByUrl('/quick-search');

    //this.name = this.route.snapshot.queryParamMap.get("returnUrl");
    // this.route.queryParamMap.subscribe(queryParams => {
    // this.name = queryParams.get("returnUrl")
    // })

    // alert(this.name);
    //alert("app component ngoninit ends");
  }

  Login(){
    //this._msalUserService.loginRedirect();
    //this._msalUserService.loginRedirect();
    this.router.navigateByUrl('/quick-search');
    //this.router.navigate(['/quick-search'], { queryParams: { returnUrl: 'rakesh' } })
  }

}
