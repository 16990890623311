<div class="container">
	<div class="row justify-content-center mb-30">
		<div class="col-md-8 col-lg-6 col-xl-5">
			<div class="text-center w-75 m-auto">
				<img src="https://www.lazard.com/images/lazard_logo_textcolor@2x.png" alt="">
			</div>
		</div>
	</div>
	<div class="row justify-content-center">
		<div class="col-md-8 col-lg-6 col-xl-5">
			<div class="card bg-pattern">
				<div class="card-body">
					<div class="text-center">
						<h3 class="text-uppercase text-danger mt-3 mb-0">Logout</h3>
						<p class="text-muted mt-3">You have successfully logged out from the site.</p>
						<p class="text-muted mt-3">It always the best idea to close the tab/browser.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
