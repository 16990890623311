import { Injectable } from '@angular/core';
import * as Msal from 'msal';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  IPublicClientApplication,
  AccountInfo,
  EndSessionRequest,
  AuthorizationUrlRequest,
  AuthenticationResult,
  PopupRequest,
  RedirectRequest,
  SilentRequest
} from "@azure/msal-browser";

interface IMsalService {
  acquireTokenPopup(request: PopupRequest): Observable<AuthenticationResult>;
  acquireTokenRedirect(request: RedirectRequest): Observable<void>;
  acquireTokenSilent(silentRequest: SilentRequest): Observable<AuthenticationResult>;
  getAccountByUsername(userName: string): AccountInfo | null;
  getAllAccounts(): AccountInfo[];
  handleRedirectObservable(): Observable<AuthenticationResult | null>;
  loginPopup(request?: PopupRequest): Observable<AuthenticationResult>;
  loginRedirect(request?: RedirectRequest): Observable<void>;
  logout(logoutRequest?: EndSessionRequest): Observable<void>;
  ssoSilent(request: AuthorizationUrlRequest): Observable<AuthenticationResult>;
}



@Injectable({
  providedIn: 'root',
})
export class MsalUserService {
  private accessToken: any;
  public clientApplication: Msal.UserAgentApplication = null;
  constructor() {
    this.clientApplication = new Msal.UserAgentApplication(
      environment.uiClienId,
      'https://login.microsoftonline.com/' + environment.tenantId,
      this.authCallback,
      {
        storeAuthStateInCookie: true
        //cacheLocation: 'localStorage'
      }
    );
  }
  public GetAccessToken(): Observable<any> {
    if (
      sessionStorage.getItem('msal.idtoken') !== undefined &&
      sessionStorage.getItem('msal.idtoken') != null
    ) {
      this.accessToken = sessionStorage.getItem('msal.idtoken');
    }
    return this.accessToken;
  }

  public authCallback(errorDesc, token, error, tokenType) {
    if (token) {
    } else {
      console.log(error + ':' + errorDesc);
    }
  }

  public getCurrentUserInfo() {
    const user = this.clientApplication.getUser();
    //alert('msal service username - ' + user);
    return user;
  }

  public logout() {
    this.clientApplication.logout();
  }

  public loginRedirect() {

    // var loginRequest = {
    //   u: ["quick-search"] // optional Array<string>
    // };

    this.clientApplication.loginRedirect(["quick-search"], "rakesh");
  }

  public loginRedirect1(request?: RedirectRequest) {
    this.clientApplication.loginRedirect(["quick-search"]);
}

//   public loginPopup(request?: AuthorizationUrlRequest): Observable<AuthenticationResult> {
//     return from(this.clientApplication.loginPopup(request));
// }

}
