import { FundHoldingDetailsComponent } from './../fund-holding-details/fund-holding-details.component';
import { FundPerformanceChartComponent } from './../fund-performance-chart/fund-performance-chart.component';
import { QuickSearchComponent } from './../quick-search/quick-search.component';
import { SearchResultComponent } from './../search-result/search-result.component';
import { FundDetailsService } from './../services/fund-details.service';
import { Component, Input, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fund-details',
  templateUrl: './fund-details.component.html',
  styleUrls: ['./fund-details.component.css']
})
export class FundDetailsComponent implements OnInit {

  @Input() hId='';
  @Input() identifierValue='';
  @Input() hName='';

  fundName: string;
  category: string;
  id:string;
  fundDetails:any=[];
  //imageSelected:boolean=false;
  imgData:any;
  imageSource:string='./../../assets/img/vanguard.jpg';

  //Holding Details
  public gridOptions: GridOptions;
  columnDefs; defaultColDef; rowSelection; domLayout; paginationPageSize;
  rowData: any;
  showLoading: boolean = false;
  gridApi: any;
  gridColumnApi:any;
  pageIndex: number;
  pageSize: number;
  TotalPages: number;
  dataFetchError:string = 'no';

  currentSeriesId: any;
  //See Trend Popup
  closeResult = '';
  popUpContent='';

  constructor(private _fundDetailsService: FundDetailsService, private router: Router, private _activateRoute: ActivatedRoute, private modalService: NgbModal) {

    this.category = this._activateRoute.snapshot.paramMap.get('cname');

    this.gridOptions = {
      suppressMovableColumns: true,
      suppressDragLeaveHidesColumns: true,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      enableCellTextSelection: true
    };

    if(this.category != '')
    {
      this.category = this.category.toLowerCase();

      // define the grid columns based on category

      if(this.category == 'index')
      {
        this.columnDefs = [
          { headerName: 'Ticker', field: 'ticker', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter'},
          { headerName: 'Percentage', field: 'percentage', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
          { headerName: 'Price', field: 'price', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true }
        ];
      }
      else
      {
        this.columnDefs = [
          { headerName: 'Company', field: 'name', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter',
          cellRenderer: function(params:any) {
            let keyData = params.data.name;
            let newLink = `<i class="fa fa-line-chart" aria-hidden="true"></i> ${keyData}`;
            return newLink;
          } },
          { headerName: 'Ticker', field: '', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
          { headerName: 'Region', field: 'region', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
          { headerName: 'Identifier', field: 'identifierName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true },
          { headerName: 'Investment Country', field: 'investmentCountry', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true },
          { headerName: 'Balance', field: 'balance', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true,
                  valueGetter:function(params:any){
                    return params.data.balance+" "+params.data.units
                  }
          },
          // { headerName: 'Unit', field: 'units', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true },
          { headerName: 'Percentage', field: 'portfolioWeight', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true },
          { headerName: 'Change', field: '', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true },
          { headerName: '% Change', field: '', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true },
          { headerName: 'Market Share', field: '', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true }
          // { headerName: 'See Trend', field: '', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true,
          //             cellRenderer: function(params:any) {
          //               let keyData = params.data.name;
          //               let newLink = `<i class="fa fa-line-chart" aria-hidden="true"></i> ${keyData}`;
          //               return newLink;
          //           }
          // }
        ];
      }
    this.pageIndex = 1;
    this.pageSize =50;

    }
  }

  ngOnInit(): void {
    this.category = this._activateRoute.snapshot.paramMap.get('cname');
    this.id = this._activateRoute.snapshot.paramMap.get('id');
    this.getFundDetails(this.category, this.id);

     //Holdings
    //if(this.category.toLocaleLowerCase() != "stock" && this.category.toLocaleLowerCase() != "bond" && this.category.toLocaleLowerCase() != "index"){
      this._activateRoute.params.subscribe(oParam => {
        if (oParam["id"] != undefined) {
          this.currentSeriesId = oParam["id"];
          this.showHoldingsFor();
        }
      });
    //}
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  getFundDetails(category:string, id:string) {
    if(this.category != '')
    {
      this.showLoading = true;
      this._fundDetailsService.fetchFundDetails(this.category, this.id).subscribe(data => {
        this.fundDetails =  data;
        if(this.category.toLowerCase() == 'etf'){this.fundName = data[0].fC_ClassName}
        else if(this.category.toLowerCase() == 'mutual fund' || this.category.toLowerCase() == 'mutualfund'){this.fundName = data[0]?.fC_ClassName}
        else if (this.category.toLowerCase() == 'bonds'){this.fundName = data[0]?.fundClassName}
        else if(this.category.toLowerCase() == 'stock'){this.fundName = data[0]?.securityName}
        else if(this.category.toLowerCase() == 'index'){this.fundName = data[0]?.indexName}

        // if(this.fundName != '')
        // {
        //   const canvas = <HTMLCanvasElement> document.getElementById('myCanvas');
        //   const ctx = canvas.getContext('2d');
        //   ctx.font="20px 'Roboto', sans-serif";
        //   // Create gradient
        //   var gradient = ctx.createLinearGradient(0, 0, ctx.lineWidth, 0);
        //   gradient.addColorStop(0,"magenta");
        //   gradient.addColorStop(0.5, "blue");
        //   gradient.addColorStop(0.9, "maroon");
        //   // Fill with gradient
        //   ctx.fillStyle = gradient;
        //   ctx.textAlign = "center";
        //   ctx.fillText(this.fundName,250,25);
        // }
        this.showLoading = false;
        },
        error => {
          console.log("Something went wrong " + error);
          this.showLoading = false;
          this.dataFetchError = 'yes';
        }
      )
    }
  }

  showHoldingsFor() {
    this.showLoading = true;
    this._fundDetailsService.getHoldingsFor(this.category.toLocaleLowerCase(), this.currentSeriesId, this.pageIndex, this.pageSize).subscribe(data => {
        this.rowData = data;
        if(this.rowData.length>0)
          this.TotalPages = data[0].totalPages;
        this.showLoading = false;
     },
      error => {
        console.log("Something went wrong " + error);
        this.showLoading = false;
        this.dataFetchError = 'yes';
      }
    );
  }

  BackToSeries() {
    this.router.navigate(['/']);
  }

  ShowFirstPage() {
    this.pageIndex = 1;
    this.showHoldingsFor();
  }

  ShowPreviousPage() {
    this.pageIndex--;
    this.showHoldingsFor();
  }

  ShowNextPage() {
    this.pageIndex++;
    this.showHoldingsFor();
  }

  ShowLastPage() {
    this.pageIndex = this.TotalPages;
    this.showHoldingsFor();
  }

  // onFileChanged(event:any) {
  //   const file = event.target.files[0];
  //   console.log(file);
  // }

  onChangeImageClick(event:any) {
    const uploadedImagefile = <File>event.target.files[0];
    const fd = new FormData();
    fd.append('image', uploadedImagefile, uploadedImagefile.name)
    this.imageSource = "assets/img/" + uploadedImagefile.name;
    this._fundDetailsService.uploadFundLogo(this.category, this.currentSeriesId, fd).subscribe(data => {
      this.imgData = data;
      this.imageSource= this.imgData[0].imagePath;
     },
      error => {
        console.log("Something went wrong uploading logo of Fund" + error);
        this.dataFetchError = 'yes';
        //this.showLoading = false;
      }
    );
  }

  //See Trends of a Holding
  onCellClicked($event:any) {
        if ($event.data.holdingId != '') {
          if ($event.colDef.headerName.toLowerCase() == 'company')
          {
            const modalRef = this.modalService.open(FundHoldingDetailsComponent, {size: <any>'lg', ariaLabelledBy: 'modal-basic-title', windowClass : "trendChartClass"});
            //modalRef.componentInstance.hId = $event.data.holdingId;
            modalRef.componentInstance.hId = $event.data.seriesId;
            modalRef.componentInstance.identifierValue = $event.data.identifierValue;
            modalRef.componentInstance.hName = $event.data.name;
          }
          else if ($event.colDef.headerName.toLowerCase() == 'see trend')
          {
            const modalRef = this.modalService.open(FundPerformanceChartComponent, {size: <any>'lg', ariaLabelledBy: 'modal-basic-title', windowClass : "trendChartClass"});
            //modalRef.componentInstance.hId = $event.data.holdingId;
            modalRef.componentInstance.hId = $event.data.seriesId;
            modalRef.componentInstance.identifierValue = $event.data.identifierValue;
            modalRef.componentInstance.hName = $event.data.name;
          }
        else {
          //alert('Holding ID is empty for company - ' + $event.data.name);
        }
      }
  }

}
