import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { FundSeriesService } from '../services/FundSeriesService.service';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-fund-series-master',
  templateUrl: './fund-series-master.component.html',
  styleUrls: ['./fund-series-master.component.css']
})
export class FundSeriesMasterComponent implements OnInit {
  public gridOptions: GridOptions;
  columnDefs; defaultColDef; rowSelection; domLayout; paginationPageSize;
  rowData: any;
  AllData: any;
  showLoading: boolean = false;
  gridApi: any;
  pageIndex: number;
  pageSize:number
  TotalPages: number;
  keyword: string;

  constructor(private _seriesMasterService: FundSeriesService, private router:Router,private _activateRoute:ActivatedRoute) {
    this.showLoading = false;

    // initialize the grid with desired settings
    this.gridOptions = {
      suppressMovableColumns: true,
      suppressDragLeaveHidesColumns: true,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      enableCellTextSelection: true
    };

    // define the grid columns
    this.columnDefs = [
      { headerName: 'Series Name', field: 'seriesName', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
      { headerName: 'CIKId', field: 'cikId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
      { headerName: 'SeriesId', field: 'seriesId', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
      { headerName: 'Series Lei', field: 'seriesLei', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
      { headerName: 'Fiscal Year End Date', field: 'fiscalYearEndDate', sortable: true, filter: 'agDateColumnFilter', unSortIcon: true, valueFormatter: function (params) { return params.data.fiscalYearEndDate.toLocaleString().split("T")[0]; } },
      { headerName: 'Total Assets', field: 'totalAssets', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true, type: 'rightAligned' },
      { headerName: 'Net Assets', field: 'netAssets', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true, type: 'rightAligned' },
      { headerName: 'Assets Invested', field: 'assetsInvested', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true, type: 'rightAligned' },
      { headerName: 'Last As Of', field: 'lastAsOf', sortable: true, filter: 'agDateColumnFilter', unSortIcon: true, valueFormatter: function (params) { return params.data.lastAsOf.toLocaleString().split("T")[0]; } },
      { headerName: 'Action', cellStyle: function (params) { return "<a href='return false;'>View Holdings</a>"; } }
    ]
    this.pageIndex = 1;
    this.pageSize = 100;
    // make request to server to load the classInfo data
    this.getSeriesMasterData();
  }

  ngOnInit(): void {
  }

  getSeriesMasterData() {
    this.showLoading = true;
    this._seriesMasterService.getFundSeriesMasters(this.pageIndex, this.pageSize).subscribe(data => {
      this.AllData = this.rowData = data;
      if(this.rowData.length>0)
      this.TotalPages = data[0].totalPages;
      this.showLoading = false;
    },
      error => {
        console.log("Something went wrong " + error);
        this.showLoading = false;
      }
    )
  }

  searchSeriesMasterData() {
    var searchKey = this.keyword;
    if (this.keyword.trim() != "") {
      this.rowData = this.rowData.filter(function (item) {
        if (item.cikId == searchKey || item.seriesId == searchKey || item.seriesLei.toLowerCase().indexOf(searchKey.toLowerCase()) !=-1 || item.seriesName.toLowerCase().indexOf(searchKey.toLowerCase()) !=-1) {
          return item;
        }
      });
    }
    else {
      this.rowData = this.AllData;
    }
  }

  onCellClicked($event) {
    if ($event.data.seriesId != '') {
      if ($event.colDef.field == 'seriesName') {
        this.router.navigate(['/holdings/q/', $event.data.seriesId]);
      }
    }
    else {
      alert('SeriesId found to be empty');
    }
  }

  ShowFirstPage() {
    this.pageIndex = 1;
    this.getSeriesMasterData();
  }

  ShowPreviousPage() {
    this.pageIndex--;
    this.getSeriesMasterData();
  }

  ShowNextPage() {
    this.pageIndex++;
    this.getSeriesMasterData();
  }

  ShowLastPage() {
    this.pageIndex = this.TotalPages;
    this.getSeriesMasterData();
  }

}
