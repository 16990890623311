import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FundDetailsService {

  private _apiURL: string;

  constructor(private http: HttpClient) {
    this._apiURL = environment.config.endpointd.apiUrl;
  }

  fetchFundDetails(category: string, id: string): Observable<any> {
    let httpOptions = this.getHeaders();
    category = category.toLowerCase();
    if(category == 'index')
    {
      return this.http.get(this._apiURL + "index/GetIndecies?IndexID=" + id, { headers: httpOptions });
    }
    else if(category == 'stock')
    {
      return this.http.get(this._apiURL + "security/getsecuritiesinfo?SecurityId=" + id, { headers: httpOptions });
    }
    else if(category == 'bond')
    {
      return this.http.get(this._apiURL + "tempsecurity/gettempsecuritiesinfo?SecurityId=" + id, { headers: httpOptions });
    }
    else if(category == 'mutual fund' || category == 'mutualfund')
    {
      //return this.http.get(this._apiURL + "classinfo/Getfundclassesinfo?FundClassInfoId=" + id, { headers: httpOptions });
      return this.http.get(this._apiURL + "mutualfund/getmutualfunds?fundseriesid=" + id, { headers: httpOptions });
    }
    else if(category == 'etf')
    {
      //console.log(this._apiURL + "etf/getetfs?ETFId=" + id);
      //return this.http.get(this._apiURL + "classinfo/Getfundclassesinfo?FundClassInfoId=" + id, { headers: httpOptions });
      return this.http.get(this._apiURL + "etf/getetfs?fundseriesid=" + id, { headers: httpOptions });
    }
    else
     {
       //Default one is Index
       return this.http.get(this._apiURL + "index/GetIndecies?IndexID=" + id + "&id=" + id, { headers: httpOptions });
      }
  }

  private getHeaders() {
    const token = localStorage.getItem("token");
    let header = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      "Access-Control-Allow-Origin" : "*"
    };
    let httpOptions = new HttpHeaders(header);
    return httpOptions;
  }

  getHoldingsFor(category_name:string, seriesId: string, pageIndex: number, pageSize: number): Observable<any> {
    let httpOptions = this.getHeaders();
    //return this.http.get(this._apiURL + "holding/getholdings/" + seriesId + "?pageIndex=" + pageIndex + "&PageSize=" + pageSize, { headers: httpOptions });
    if(category_name == "index")
      return this.http.get(this._apiURL + "index/getindexholdings/" + seriesId + "?pageIndex=" + pageIndex + "&PageSize=" + pageSize, { headers: httpOptions });
    else
      return this.http.get(this._apiURL + "holding/getlatestholdings/" + seriesId + "?pageIndex=" + pageIndex + "&PageSize=" + pageSize, { headers: httpOptions });
}

uploadFundLogo(category: string, fundSeriesId: string, uploadData): Observable<any> {
  let httpOptions = this.getHeaders();
  category = category.toLowerCase();
  //console.log(this._apiURL + "UploadFundLogo?category=" + category + "&ID="+id);

  //const formData = new FormData();

    //formData.append('image', uploadData);
    // for (var key in uploadData) {
    //   console.log(key, uploadData[key]);
    // }
    //return this.http.post("/api/v1/image-upload", formData);

    return this.http.post(this._apiURL + "UploadFundLogo?category=" + category + "&fundSeriesId="+fundSeriesId, uploadData, { headers: httpOptions });

  // if(category == 'index')
  // {
  //   return this.http.post(this._apiURL + "index/GetIndecies?IndexID=" + id, uploadData, { headers: httpOptions });
  // }
  // else if(category == 'stock')
  // {
  //   return this.http.get(this._apiURL + "security/getsecuritiesinfo?SecurityId=" + id, { headers: httpOptions });
  // }
  // else if(category == 'bond')
  // {
  //   return this.http.get(this._apiURL + "tempsecurity/gettempsecuritiesinfo?SecurityId=" + id, { headers: httpOptions });
  // }
  // else if(category == 'mutual fund')
  // {
  //   return this.http.get(this._apiURL + "classinfo/Getfundclassesinfo?FundClassInfoId=" + id, { headers: httpOptions });
  // }
  // else if(category == 'etf')
  // {
  //   return this.http.get(this._apiURL + "classinfo/Getfundclassesinfo?FundClassInfoId=" + id, { headers: httpOptions });
  // }
  // else
  //  {
  //    //Default one is Index
  //    return this.http.get(this._apiURL + "index/GetIndecies?IndexID=" + id + "&id=" + id, { headers: httpOptions });
  //   }
}

}
