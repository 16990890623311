import { QuickSearchService } from './../services/quick-search.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { MsalUserService } from './../services/msaluser.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.css']

})
export class QuickSearchComponent implements OnInit {

  searchTB: string = "";
  searchResultDataList = [];
  searchType: string = '0';
  showBox = false;
  isSearching = false;

  userDetails:any;
  userName:string = "";

  constructor(private route: ActivatedRoute, private router: Router, private quickSearchService: QuickSearchService, private _msalUserService: MsalUserService) {
    // this.userDetails = this._msalUserService.getCurrentUserInfo();
    //  if(this.userDetails){
    //   this.userName = this.userDetails.name;
    // }
  }

  ngOnInit(): void {

  }

  onSearchTypeSelectionChange(stype: string): void {
    this.searchType = stype;
  }

  onSearchTBKeyUp(event: KeyboardEvent): void {
    if (this.searchTB.length > 2) {
      this.showBox = true;
      this.isSearching = true;
      this.quickSearchService.getQuickSearch(this.searchType, this.searchTB).subscribe(data => {
        this.searchResultDataList = data;
        this.isSearching = false;
      },
        error => {
          console.log("onSearchTBKeyUp: Something went wrong " + error);
        }
      );

    } else {
      this.showBox = false;
    }
  }

  ClearSearch(): void {
    this.HandleShowHideSearchOutput();
  }

  HandleShowHideSearchOutput(): void {
    if (this.searchTB.length == 0)
      this.showBox = false;
    else
      this.showBox = true;
  }
}
