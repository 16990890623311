<div class="text-center">
    <div class="secLogo">
      <a href=""><img src="../assets/img/logo.jpg" width="250" alt="Security Master Logo"
            title="Security Master" /></a>
    </div>
    <div class="form-check-inline">
        <label class="form-check-label">
            <input href (change)="onSearchTypeSelectionChange('0')" type="radio" class="form-check-input" value="0"
                name="searchBy" checked>Search Starts with
        </label>
    </div>
    <div class="form-check-inline">
        <label class="form-check-label">
            <input (change)="onSearchTypeSelectionChange('1')" type="radio" class="form-check-input" value="1"
                name="searchBy">Search contains
        </label>
    </div>
</div>

<div id="cover">
    <form>
        <div class="tb">
            <div class="td" id="s-cover">
                <i class="fa fa-search searchButton" aria-hidden="true"></i>
            </div>
            <div class="td">
                <input name="searchTB" type="search" [(ngModel)]="searchTB" [value]="searchTB"
                    (keyup)="onSearchTBKeyUp($event)" placeholder="Search ETF, Mutual Funds, Bonds, Stock etc" required
                    autocomplete="off" (search)="ClearSearch()">
                <div id="searchResultDiv" *ngIf="searchResultDataList.length>0 && showBox">
                    <div *ngFor="let item of searchResultDataList;">
                        <div class="item-name">
                            <a *ngIf="item.type != null && (item.type.toLowerCase() =='etf' || item.type.toLowerCase() == 'mutualfund')" [routerLink]="['/fund-details', item.type, item.series_Id]">
                                {{item.title}}
                            </a>
                            <a *ngIf="item.type != null && (item.type.toLowerCase() =='bond' || item.type.toLowerCase() == 'stock' || item.type.toLowerCase() == 'index')" [routerLink]="['/fund-details', item.type, item.id]">
                                {{item.title}}
                            </a>
                        </div>
                        <div class="item-desc">
                            <a *ngIf="item.type != null && (item.type.toLowerCase() =='etf' || item.type.toLowerCase() == 'mutualfund')" [routerLink]="['/fund-details', item.type, item.series_Id]" [ngClass]="
                                            item.type.toLowerCase() == 'etf'? 'linkE':
                                            (item.type.toLowerCase() == 'mutualfund' ? 'linkM' : ''
                                        )">
                                {{item.type}}
                            </a>
                            <a *ngIf="item.type != null && (item.type.toLowerCase() =='bond' || item.type.toLowerCase() == 'stock' || item.type.toLowerCase() == 'index')" [routerLink]="['/fund-details', item.type, item.id]" [ngClass]="
                                            item.type.toLowerCase() == 'bond'? 'linkB' :
                                            (item.type.toLowerCase() == 'stock'? 'linkS' :
                                            (item.type.toLowerCase() == 'index'? 'linkI' : ''
                                        ))">
                                {{item.type}}
                            </a>
                        </div>
                    </div>
                </div>
                <div id="searchDiv" *ngIf="searchResultDataList.length<=0 && showBox && isSearching">
                   Searching...
                </div>
                <div id="searchResultDiv" *ngIf="searchResultDataList.length<=0 && showBox && !isSearching">
                    No Record Found!
                </div>

                <br>
                <div class="d-flex">
                    <ul class="list-inline mx-auto justify-content-center mt-4">
                        <li style="padding-top:9px"> Quick Search:</li>
                        <li><a [routerLink]="['/search-result/ETF']" class="list-inline-item linkE">ETF</a></li>
                        <li><a [routerLink]="['/search-result/Mutual Fund']" class="list-inline-item linkM">Mutual Funds</a></li>
                        <li><a [routerLink]="['/search-result/Bond']" class="list-inline-item linkB">Bonds</a></li>
                        <li><a [routerLink]="['/search-result/Stock']" class="list-inline-item linkS">Stocks</a></li>
                        <li><a [routerLink]="['/search-result/Index']" class="list-inline-item linkI">Index</a></li>
                    </ul>
                </div>

            </div>
        </div>
    </form>
</div>
