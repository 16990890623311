<div class="container-fluid">

  <!-- <div class="row">
    <div class="col-12 text-center"
      style="margin-top:5px;background:gold;border:solid 2px red;color:black;padding:20px 0;margin-bottom:30px;">
      <h6>You are Searching for....</h6>
      <strong>Category:</strong> {{category}}<br>
      <strong>ID:</strong> {{id}}
      <br><br>
      <strong>Fund Details</strong><br>
      {{fundDetails | json}}
    </div>
  </div> -->

  <div class="row">
    <!-- <div class="col-1"> -->

      <!-- New One -->
      <!-- <img src="{{imageSource}}" alt="Vanguard" class="img-fluid">
      <label for="files" class="uploadImg"><i class="fa fa-refresh" aria-hidden="true"></i></label>
      <input id="files" style="visibility:hidden;" type="file" (change)="onChangeImageClick($event)"> -->

      <!-- Old one -->
      <!-- <button class="uploadImg" *ngIf="imageSelected">Upload</button> -->
      <!-- <input type="file" (change)="onFileChanged($event)" accept="image/png, image/gif, image/jpeg"> -->
      <!-- <canvas id="myCanvas" width="500" height="40">
        Your browser does not support the HTML5 canvas tag.</canvas> -->
    <!-- </div> -->



    <div class="col-12" style="margin-top:5px;text-align: center;">
      <!-- {{fundDetails | json}} -->
      <h3 style="text-decoration: underline;">{{fundName | decodeHtmlString}} | <small>Ownsership Summary</small><br>
        </h3>
      <strong>{{fundDetails[0].ticker != undefined ? fundDetails[0].ticker + '&nbsp; | '  : ''}}
        <!-- <span style="color:red">ARCA; VV, VLAC, VLCA.X, VLIS.X (MI KEY: 22939695)</span> -->
      </strong>
      <a id="catLink" [ngClass]="category.toLowerCase() == 'etf'? 'linkE':
        (category.toLowerCase() == 'mutual fund' ? 'linkM' :
        (category.toLowerCase() == 'bond'? 'linkB' :
        (category.toLowerCase() == 'stock'? 'linkS' :
        (category.toLowerCase() == 'index'? 'linkI' : '' ))))">
         {{category | uppercase}}
      </a>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-md-12">
      <hr>
    </div>
  </div> -->



  <div class="row">
    <div class="col-sm-12">
      <!-- Nav tabs -->

      <ul class="nav nav-pills justify-content-left">
        <li>
          <a class="active" id="summary-tab" data-toggle="tab" href="#summary">Summary</a>
        </li>
        <li>
          <a id="holdings-tab" data-toggle="tab" href="#holdings">Holdings</a>
        </li>
        <!-- <li>
          <a id="topHolders-tab" data-toggle="tab" href="#topHolders">Top Holders</a>
        </li>
        <li>
          <a id="topMutualFundHolders-tab" data-toggle="tab" href="#topMutualFundHolders">Top Mutual Fund
            Holders</a>
        </li>
        <li>
          <a id="instOwnerType-tab" data-toggle="tab" href="#instOwnerType">Inst. Owner Type</a>
        </li>
        <li>
          <a id="country-tab" data-toggle="tab" href="#country">Country</a>
        </li>

        <li>
          <a id="style-tab" data-toggle="tab" href="#style">Style</a>
        </li>
        <li>
          <a id="marketcap-tab" data-toggle="tab" href="#marketcap">Market Cap</a>
        </li>
        <li>
          <a id="turnover-tab" data-toggle="tab" href="#turnover">Turnover</a>
        </li>
        <li>
          <a id="topBuyersAndSellers-tab" data-toggle="tab" href="#topBuyersAndSellers">Top Buyers and
            Sellers</a>
        </li>
        <li>
          <a id="ownershipActivity-tab" data-toggle="tab" href="#ownershipActivity">Ownership Activity</a>
        </li>
        <li>
          <a id="detailed-tab" data-toggle="tab" href="#detailed">Detailed</a>
        </li> -->
      </ul>
      <!-- Tab panes -->
      <div class="tab-content clearfix">
        <div class="tab-pane active" id="summary">

          <div class="row">
            <div class="sm-12 col-7 mt-30">

              <div *ngIf="showLoading  == true">
                <img src="../assets/img/Loading-bar.gif" alt="Loading" /><br>
                <b>LOADING...</b>
              </div>


              <table class="table table-bordered" *ngIf="category.toLowerCase() != 'index' && category.toLowerCase() != 'stock'  && category.toLowerCase() != 'bond' && showLoading  == false">
                  <tr>
                    <td class="lgBackground">Fund Class ID</td>
                    <td class="lgBackground">Fund Class Name</td>
                    <td class="lgBackground">Series ID</td>
                    <td class="lgBackground">Ticker</td>
                    <td class="lgBackground">Series Name</td>
                  </tr>
                  <tr>
                    <td class="text-left vBack">{{fundDetails[0]?.fC_ClassId}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fC_ClassName}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fC_SeriesId}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fC_Ticker}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fS_SeriesName}}</td>
                  </tr>
                  <tr>
                    <td class="lgBackground">Issuer Name</td>
                    <td class="lgBackground">Reg File ID</td>
                    <td class="lgBackground">Reg Lei</td>
                    <td class="lgBackground">Series Lei</td>
                    <td class="lgBackground">Reporting Date</td>
                  </tr>
                  <tr>
                    <td class="text-left vBack">{{fundDetails[0]?.fS_IssuerName}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fS_RegFileId}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fS_RegLei}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fS_SeriesLei}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fS_ReportingDate | date:'dd-MMM-yyyy' }}</td>
                  </tr>
                  <tr>
                    <td class="lgBackground">Address</td>
                    <td class="lgBackground">Total Assets</td>
                    <td class="lgBackground">Total Liabilities</td>
                    <td class="lgBackground">Net Assets</td>
                    <td class="lgBackground">Exchange Code</td>
                  </tr>
                  <tr>
                    <td class="text-left vBack">{{fundDetails[0]?.fS_Address}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fS_TotalAssets}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fS_TotalLiabilities}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fS_NetAssets}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.exchangeCode}}</td>
                  </tr>
                  <tr>
                    <td class="lgBackground">Security Type</td>
                    <td class="lgBackground">Market Sector</td>
                    <td class="lgBackground">FIGI</td>
                    <td class="lgBackground">Share Class</td>
                    <td class="lgBackground">Index</td>
                  </tr>
                  <tr>
                    <td class="text-left vBack">{{fundDetails[0]?.securityType}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.marketSector}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.figi}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.shareClass}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.index}}</td>
                  </tr>
                  <tr>
                    <td class="lgBackground">Fees</td>
                    <td class="lgBackground">Category 1</td>
                    <td class="lgBackground">Category 2</td>
                    <td class="lgBackground">Category 3</td>
                    <td class="lgBackground">Market Cap</td>
                  </tr>
                  <tr>
                    <td class="text-left vBack">{{fundDetails[0]?.fees}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.category1}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.category2}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.category3}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.marketCap}}</td>
                  </tr>
                  <tr>
                    <td class="lgBackground">Average Volume</td>
                    <td class="lgBackground">Actions</td>
                    <td class="lgBackground">CIK ID</td>
                    <td class="lgBackground">&nbsp;</td>
                    <td class="lgBackground">&nbsp;</td>
                  </tr>
                  <tr>
                    <td class="text-left vBack">{{fundDetails[0]?.averageVolume}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.actions}}</td>
                    <td class="text-left vBack">{{fundDetails[0]?.fS_CIKId}}</td>
                    <td class="text-left vBack">&nbsp;</td>
                    <td class="text-left vBack">&nbsp;</td>
                  </tr>
              </table>
              <table class="table table-bordered" *ngIf="category.toLowerCase() == 'index' && showLoading  == false">
                <tr>
                  <td class="lgBackground">indexName</td>
                  <td class="lgBackground">close</td>
                  <td class="lgBackground">currency</td>
                  <td class="lgBackground">exchange</td>
                  <td class="lgBackground">high</td>
                </tr>
                <tr>
                  <td class="text-left vBack">{{fundDetails[0]?.indexName}}</td>
                  <td class="text-left vBack">{{fundDetails[0]?.close}}</td>
                  <td class="text-left vBack">{{fundDetails[0]?.currency}}</td>
                  <td class="text-left vBack">{{fundDetails[0]?.exchange}}</td>
                  <td class="text-left vBack">{{fundDetails[0]?.high}}</td>
                </tr>
                <tr>
                  <td class="lgBackground">low</td>
                  <td class="lgBackground">open</td>
                  <td class="lgBackground">symbol</td>
                  <td class="lgBackground">volume</td>
                  <td class="lgBackground">&nbsp;</td>
                </tr>
                <tr>
                  <td class="text-left vBack">{{fundDetails[0]?.low}}</td>
                  <td class="text-left vBack">{{fundDetails[0]?.open}}</td>
                  <td class="text-left vBack">{{fundDetails[0]?.symbol}}</td>
                  <td class="text-left vBack">{{fundDetails[0]?.volume}}</td>
                  <td class="text-left vBack">&nbsp;</td>
                </tr>
            </table>
            <table class="table table-bordered" *ngIf="category.toLowerCase() == 'stock' && showLoading  == false">
              <tr>
                <td class="lgBackground">securityName</td>
                <td class="lgBackground">ticker</td>
                <td class="lgBackground">active</td>
                <td class="lgBackground">address</td>
                <td class="lgBackground">bloomberg</td>
              </tr>
              <tr>
                <td class="text-left vBack">{{fundDetails[0]?.securityName}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.ticker}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.active}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.address}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.bloomberg}}</td>
              </tr>
              <tr>
                <td class="lgBackground">ceo</td>
                <td class="lgBackground">cik</td>
                <td class="lgBackground">cikId</td>
                <td class="lgBackground">volume</td>
                <td class="lgBackground">companyName</td>
              </tr>
              <tr>
                <td class="text-left vBack">{{fundDetails[0]?.ceo}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.cik}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.cikId}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.volume}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.companyName}}</td>
              </tr>



              <tr>
                <td class="lgBackground">currencyName</td>
                <td class="lgBackground">cusip</td>
                <td class="lgBackground">description</td>
                <td class="lgBackground">employees</td>
                <td class="lgBackground">exchange</td>
              </tr>
              <tr>
                <td class="text-left vBack">{{fundDetails[0]?.currencyName}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.cusip}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.description}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.employees}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.exchange}}</td>
              </tr>
              <tr>
                <td class="lgBackground">exchangeCode</td>
                <td class="lgBackground">figi</td>
                <td class="lgBackground">figiComposite</td>
                <td class="lgBackground">isin</td>
                <td class="lgBackground">market</td>
              </tr>
              <tr>
                <td class="text-left vBack">{{fundDetails[0]?.exchangeCode}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.figi}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.figiComposite}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.isin}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.market}}</td>
              </tr>
              <tr>
                <td class="lgBackground">marketCap</td>
                <td class="lgBackground">region</td>
                <td class="lgBackground">sector</td>
                <td class="lgBackground">securityName</td>
                <td class="lgBackground">&nbsp;</td>
              </tr>
              <tr>
                <td class="text-left vBack">{{fundDetails[0]?.marketCap}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.region}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.sector}}</td>
                <td class="text-left vBack">{{fundDetails[0]?.securityName}}</td>
                <td class="text-left vBack">&nbsp;</td>
              </tr>
          </table>
            </div>
            <div class="col-4  m-30">
              <h4>Chart</h4>
              <p>Chart will appear here</p>

            </div>
          </div>

          <!-- <div class="row">
            <div class="col-sm-8">
              <h5 class="title">Ownership Summary</h5>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>TYPE</th>
                    <th>COMMON STOCK EQUIVALENT HELD</th>
                    <th>PERCENTAGE OF COMMON SHARE OUTSTANDING(%)</th>
                    <th>MARKET VALUE($M)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Institutions</td>
                    <td class="text-center">79,514,600</td>
                    <td class="text-center">33.97</td>
                    <td class="text-center">14,681</td>
                  </tr>
                  <tr>
                    <td>Corporations (Public)</td>
                    <td class="text-center">309,168</td>
                    <td class="text-center">0.13</td>
                    <td class="text-center">57.1</td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td class="text-center">234,096,849</td>
                    <td class="text-center">34.10</td>
                    <td class="text-center">34.10</td>
                  </tr>
                </tbody>
              </table>

              <h5 class="title mt60">Float Summary</h5>
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>Vanguard Index Funds - Vanguar</td>
                    <td class="text-right">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Standarized Dataerdized Data</td>
                    <td class="text-right">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Mar-11-2021</td>
                    <td class="text-right">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>+Strategic Corporate Investors</td>
                    <td class="text-right">309,168</td>
                  </tr>
                  <tr>
                    <td>=Total Shares Exclude From Float</td>
                    <td class="text-right">309,168</td>
                  </tr>
                  <tr>
                    <td>Free Float Shares</td>
                    <td class="text-right">234,096,849</td>
                  </tr>
                  <tr>
                    <td>/Shares Outstanding</td>
                    <td class="text-right">234,096,849</td>
                  </tr>
                  <tr>
                    <td>=Float %</td>
                    <td class="text-right">&nbsp;</td>
                  </tr>
                </tbody>
              </table>


            </div>
            <div class="col-sm-4">
              <h5 class="title">Ownership Summary</h5>
              <img src="./../../assets/img/ownership-summary.jpg" alt="ownership summary" class="img-fluid">
            </div>
          </div> -->
        </div>
        <div class="tab-pane" id="holdings">
          <div class="row">
            <div class="sm-12 col-12 mt-30">
              <div *ngIf="rowData?.length>0">

                <!-- <h1>Check RowData - {{rowData.length}}</h1> -->
                <h3>Holdings <small style="font-size: 20px;">(As on {{rowData && rowData[0].asOnDate | date:'d.MMM.yyyy'}})</small></h3>
                <!-- <p>
                  Fund Holdings for SeriesId: <b>{{currentSeriesId}}</b>
                </p> -->
                <div id="fundHoldingsGrid">
                  <ag-grid-angular [rowData]="rowData" [columnDefs]="columnDefs" style="width: 100%; height: 500px;table-layout: fixed;"
                    [pagination]="false" [defaultColDef]="defaultColDef" [rowSelection]="rowSelection"
                    [gridOptions]="gridOptions" [domLayout]="domLayout" [suppressScrollOnNewData]=true
                    [paginationPageSize]="paginationPageSize" class="ag-theme-alpine"
                    (cellClicked)="onCellClicked($event)" suppressScrollOnNewData=true>
                  </ag-grid-angular>
                  <div style="width:100%;">
                    <div style="float:left;width:50%;">
                      <input type="button" (click)="BackToSeries();" value="<< Back" style="padding:5px;margin:5px;" />
                    </div>
                    <div style="width:50%;float:left;text-align:right;">
                      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==1"
                        (click)="ShowFirstPage();" value="<< " title="First page" />
                      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==1"
                        (click)="ShowPreviousPage();" value="< " title="Previous page" />
                      <input type="text" disabled style="margin:5px;padding:10px;width:50px;"
                        value="{{this.pageIndex}} of {{this.TotalPages}}" />
                      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==this.TotalPages"
                        (click)="ShowNextPage();" value="> " title="Next page" />
                      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==this.TotalPages"
                        (click)="ShowLastPage();" value=">> " title="Last page" />
                    </div>
                  </div>
                </div>

            </div>
            <!--End of Holding data available-->
            <!-- <div *ngIf="rowData === undefined">
              <h4>No Holding Data Available.</h4>
            </div> -->
            <!--<div *ngIf="rowData !== undefined && rowData?.length<=0">
              <h4>No Holding Data Found.</h4>
            </div>
            <div *ngIf="rowData !== undefined && rowData?.length>0">
              <h4>Fetching Holding Data...</h4>
            </div> -->
            <div *ngIf="rowData && rowData?.length<=0 && showLoading  == false && dataFetchError == 'yes'">
              <h4>Error Fetching Holding Data.</h4>
            </div>
            <!--End of No Holding data available-->
            </div>
          </div>
        </div>
        <!--
        <div class="tab-pane" id="topHolders">
          <div class="row">
            <div class="col-sm-12">
              <div class="jumbotron">
                <h1>topHolders</h1>
                <p>This is profile tab.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="topMutualFundHolders">
          <div class="row">
            <div class="col-sm-12">
              <div class="jumbotron">
                <h1>topMutualFundHolders</h1>
                <p>This is home message.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="instOwnerType">
          <div class="row">
            <div class="col-sm-12">
              <div class="jumbotron">
                <h1>instOwnerType</h1>
                <p>This is settings.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="country">
          <div class="row">
            <div class="col-sm-12">
              <div class="jumbotron">
                <h1>country</h1>
                <p>This is settings.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="style">
          <div class="row">
            <div class="col-sm-12">
              <div class="jumbotron">
                <h1>style</h1>
                <p>This is settings.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="marketcap">
          <div class="row">
            <div class="col-sm-12">
              <div class="jumbotron">
                <h1>marketcap</h1>
                <p>This is settings.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="turnover">
          <div class="row">
            <div class="col-sm-12">
              <div class="jumbotron">
                <h1>turnover</h1>
                <p>This is settings.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="topBuyersAndSellers">
          <div class="row">
            <div class="col-sm-12">
              <div class="jumbotron">
                <h1>topBuyersAndSellers</h1>
                <p>This is settings.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="ownershipActivity">
          <div class="row">
            <div class="col-sm-12">
              <div class="jumbotron">
                <h1>ownershipActivity</h1>
                <p>This is settings.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="detailed">
          <div class="row">
            <div class="col-sm-12">
              <div class="jumbotron">
                <h1>detailed</h1>
                <p>This is settings.</p>
              </div>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>
</div>
