<div class="container">
	<div class="row justify-content-center m30">
		<div class="col-md-8 col-lg-6 col-xl-5">
			<div class="text-center w-75 m-auto">
        <img src="../assets/img/logo.jpg" width="250" alt="Security Master Logo" title="Security Master" />
			</div>
		</div>
	</div>
	<div class="row justify-content-center">
		<div class="col-md-12 col-lg-12 col-xl-12">
			<div class="card bg-pattern">
				<div class="card-body">
					<div class="text-center">
						<h3 class="text-uppercase text-danger mt-3 mb-0">Page not found</h3>
						<p class="text-muted mt-3">The url you visited is no longer valid. Kindly goto the <a href="/">home page</a> and navigate.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
