<p>
  Fund Holdings for SeriesId: <b>{{currentSeriesId}}</b>
</p>

<div id="fundHoldingsGrid">

  <ag-grid-angular [rowData]="rowData"
                   [columnDefs]="columnDefs"
                   style="width: 100%; height: 1000px;table-layout: fixed;"
                   [pagination]="false"
                   [defaultColDef]="defaultColDef"
                   [rowSelection]="rowSelection"
                   [gridOptions]="gridOptions"
                   [domLayout]="domLayout"
                   [suppressScrollOnNewData]=true
                   [paginationPageSize]="paginationPageSize"
                   class="ag-theme-alpine">
  </ag-grid-angular>
<div style="width:100%;">
    <div style="float:left;width:50%;">
      <input type="button" (click)="BackToSeries();" value="<< Back" style="padding:5px;margin:5px;"/>
    </div>
    <div style="width:50%;float:left;text-align:right;">
      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==1" (click)="ShowFirstPage();" value="<< " title="First page" />
      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==1" (click)="ShowPreviousPage();" value="< " title="Previous page" />
      <input type="text" disabled style="margin:5px;padding:10px;width:50px;" value="{{this.pageIndex}} of {{this.TotalPages}}" />
      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==this.TotalPages" (click)="ShowNextPage();" value="> " title="Next page" />
      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==this.TotalPages" (click)="ShowLastPage();" value=">> " title="Last page" />
    </div>
</div>
</div>
