import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalUserService } from './services/msaluser.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title:string = 'Securities Master';
  userDetails:any;
  userName:string = "";

  constructor(private _msalUserService: MsalUserService, private router:Router){

  }

  ngOnInit(){
    this.userDetails = this._msalUserService.getCurrentUserInfo();
     if(this.userDetails){
      this.userName = this.userDetails.name;
    }
  }

  public logoutUser()
  {
    this._msalUserService.logout();
    //this.router.navigate(['/logout']);
  }
}
