<p>
 Fund Series Master
</p>
<p>
  <input type="text" [(ngModel)]="keyword" placeholder="Search by CikId, Series Name, SeriesId, Series-LEI" style="height:35px; width:500px;"/>
  &nbsp;
  <input type="button" value="Go!"  style="height:40px;width:50px;" (click)="searchSeriesMasterData();"/>
</p>

<div id="fundSeriesMasterGrid">

  <ag-grid-angular [rowData]="rowData"
                   [columnDefs]="columnDefs"
                   style="width: 100%; height: 500px;"
                   [defaultColDef]="defaultColDef"
                   [rowSelection]="rowSelection"
                   [gridOptions]="gridOptions"
                   [domLayout]="domLayout"
                   (cellClicked)="onCellClicked($event)"
                   class="ag-theme-alpine">
  </ag-grid-angular>
  <ng-template #fundSeriesTemplate>

  </ng-template>
  <div style="width:100%;">
    <div style="float:left;width:50%;">
      &nbsp;
    </div>
    <div style="width:50%;float:left;text-align:right;">
      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==1 || this.showLoading==true" (click)="ShowFirstPage();" value="<< " title="First page" />
      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==1 || this.showLoading==true" (click)="ShowPreviousPage();" value="< " title="Previous page" />
      <input type="text" disabled style="margin:5px;padding:10px;width:50px;" value="{{this.pageIndex}} of {{this.TotalPages}}" />
      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==this.TotalPages || this.showLoading==true" (click)="ShowNextPage();" value="> " title="Next page" />
      <input type="button" style="padding:10px;margin:5px;" [disabled]="this.pageIndex==this.TotalPages || this.showLoading==true" (click)="ShowLastPage();" value=">> " title="Last page" />
    </div>

  </div>
</div>
