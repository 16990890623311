import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuickSearchService {

  private _apiURL: string;

  constructor(private http: HttpClient) {
    this._apiURL = environment.config.endpointd.apiUrl;
  }

  getQuickSearch(searchType: string, searchKeyword: string): Observable<any> {
    let httpOptions = this.getHeaders();
    return this.http.get(this._apiURL + "home/search?searchType=" + searchType + "&searchKeyword=" + searchKeyword, { headers: httpOptions });
}

  private getHeaders() {
    const token = localStorage.getItem("token");
    let header = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      "Access-Control-Allow-Origin" : "*"
    };
    let httpOptions = new HttpHeaders(header);
    return httpOptions;
  }

}
