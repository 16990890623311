import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { ClassInfoService } from '../services/ClassInfo.service';

@Component({
  selector: 'app-class-info',
  templateUrl: './class-info.component.html',
  styleUrls: ['./class-info.component.css']
})
export class ClassInfoComponent implements OnInit {

public gridOptions: GridOptions;
columnDefs; defaultColDef; rowSelection; domLayout; paginationPageSize;
rowData: any;
showLoading: boolean = false;
gridApi: any;
currentPage: number;
TotalRecords: number;

  constructor(private classService:ClassInfoService) {
    this.showLoading = true;
    this.paginationPageSize = 100;
    this.rowSelection = 'multiple';

    // initialize the grid with desired settings
    this.gridOptions = {
      suppressMovableColumns: true,
      suppressDragLeaveHidesColumns: true,
      rowSelection: 'multiple',
      rowDeselection: true,
      suppressRowClickSelection: true,
      enableCellTextSelection: true
    };

    // define the grid columns
    this.columnDefs = [
      { headerName: 'Class ID', field: 'classId', sortable: true, unSortIcon: true },
      { headerName: 'Class Name', field: 'className', sortable: true, unSortIcon: true },
      { headerName: 'SeriesId', field: 'seriesId', sortable: true, unSortIcon: true },
    ]

   // make request to server to load the classInfo data
    this.getClassInfoList();
  }

  ngOnInit(): void {

  }

  getClassInfoList() {
    this.classService.getClassInfoMasters().subscribe(data => {
      this.rowData = data;
    },
      error => {
        console.log("Something went wrong " + error);
        this.showLoading = false;
      }
    )
  }

}
