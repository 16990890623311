import { FundHoldingChartService } from './../services/fund-holding-chart.service';
import { Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-fund-performance-chart',
  templateUrl: './fund-performance-chart.component.html',
  styleUrls: ['./fund-performance-chart.component.css']
})
export class FundPerformanceChartComponent implements OnInit {

  @Output() hId: string;
  @Output() identifierValue: string;
  @Output() hName: string;

  balanceData:string ='';
  asOnDateData:string='';
  dateArray:string[]=[];
  balanceArray:string[]=[];
  highcharts=Highcharts;
  chartOptions:any;

  constructor(private _activatedRouter: ActivatedRoute, private router: Router, private fundholdingchartservcie: FundHoldingChartService) {}

  ngOnInit(): void {
    this._activatedRouter.params.subscribe(oParam => {
      if (this.hId != '' && this.identifierValue != '') {
        this.showHoldingsGraph(this.hId, this.identifierValue);
      }
    });
  }

  showHoldingsGraph(hId, identifierValue) {
    this.fundholdingchartservcie.getHoldingGraph(hId, identifierValue).subscribe(data => {

      for(let i=0; i<data.length; i++){
        this.asOnDateData+= data[i].asOnDate.slice(0, 10) + ",";
        this.balanceData+= data[i].balance + ",";
      }

      this.dateArray = this.asOnDateData.replace(/,\s*$/, "").split(',');
      this.balanceArray = this.balanceData.replace(/,\s*$/, "").split(',').map(eval);

      this.LoadChart(this.dateArray, this.balanceArray);
     },
      error => {
        console.log("Something went wrong " + error);
      }
    );
  }

  LoadChart(DA:string[], BA:string[]){
      this.chartOptions = {
        chart: {
          type: "spline"
        },
        title: {
          text: "Trend"
        },
        subtitle: {
          text: "Source: SecMaster.Syven.com"
        },
        xAxis:{
          ordinal: true,
          categories:DA
          //categories:["2025-04-30", "2021-01-31", "2020-10-31", "2020-04-30", "2020-01-31"]
        },
        yAxis: {
          title:{
              text:"No. of Shares"
          }
        },
        tooltip: {
          valueSuffix:""
        },
        series: [{ name:"As on Date", data: BA }]
        //series: [{ name:"No. of Shares", data: [394, 191, 160, 94, 99] }]
    };
  }
}
