import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn:'root'})
export class FundHoldingsService {

  private _apiURL: string;

  constructor(private http: HttpClient) {
    this._apiURL = environment.config.endpointd.apiUrl;
  }

  getHoldingsFor(seriesId: string, pageIndex: number, pageSize: number): Observable<any> {
      let httpOptions = this.getHeaders();
      //return this.http.get(this._apiURL + "holding/getholdings/" + seriesId + "?pageIndex=" + pageIndex + "&PageSize=" + pageSize, { headers: httpOptions });
      return this.http.get(this._apiURL + "holding/getlatestholdings/" + seriesId + "?pageIndex=" + pageIndex + "&PageSize=" + pageSize, { headers: httpOptions });
  }

  private getHeaders() {
    const token = localStorage.getItem("token");
    let header = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      "Access-Control-Allow-Origin" : "*"
    };
    let httpOptions = new HttpHeaders(header);
    return httpOptions;
  }

}

