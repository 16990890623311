import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { FundHoldingsService } from '../services/FundHoldingsService.service';

@Component({
  selector: 'app-fund-holdings',
  templateUrl: './fund-holdings.component.html',
  styleUrls: ['./fund-holdings.component.css']
})
export class FundHoldingsComponent implements OnInit {
  public gridOptions: GridOptions;
  columnDefs; defaultColDef; rowSelection; domLayout; paginationPageSize;
  rowData: any;
  showLoading: boolean = false;
  gridApi: any;
  gridColumnApi:any;
  pageIndex: number;
  pageSize: number;
  TotalPages: number;

  currentSeriesId: any;
    constructor(private _activatedRouter: ActivatedRoute, private router: Router, private holdingServices: FundHoldingsService) {

      this.gridOptions = {
        suppressMovableColumns: true,
        suppressDragLeaveHidesColumns: true,
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        enableCellTextSelection: true
      };

      // define the grid columns
      this.columnDefs = [
        { headerName: 'Name', field: 'name', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
        { headerName: 'LEI', field: 'lei', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
        { headerName: 'Cusip', field: 'cusip', sortable: true, unSortIcon: true, filter: 'agTextColumnFilter' },
        { headerName: 'Identifier Name', field: 'identifierName', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true },
        { headerName: 'Identifier Value', field: 'identifierValue', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true, },
        { headerName: 'Balance', field: 'balance', filter: 'agNumberColumnFilter', sortable: true, unSortIcon: true, type: 'rightAligned' },
        { headerName: 'Units', field: 'units', filter: 'agTextColumnFilter', sortable: true, unSortIcon: true },
        { headerName: 'Currency', field: 'currency', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true }
      ];
      this.pageIndex = 1;
      this.pageSize =50;
    }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }

  ngOnInit(): void {
    this._activatedRouter.params.subscribe(oParam => {
      if (oParam["id"] != undefined) {
        this.currentSeriesId = oParam["id"];
        this.showHoldingsFor();
      }
    });
  }

  showHoldingsFor() {
    this.holdingServices.getHoldingsFor(this.currentSeriesId, this.pageIndex, this.pageSize).subscribe(data => {
      this.rowData = data;
      if(this.rowData.length>0)
      this.TotalPages = data[0].totalPages;
     },
      error => {
        console.log("Something went wrong " + error);
        this.showLoading = false;
      }
    );
  }

  BackToSeries() {
    this.router.navigate(['/']);
  }

  ShowFirstPage() {
    this.pageIndex = 1;
    this.showHoldingsFor();
  }

  ShowPreviousPage() {
    this.pageIndex--;
    this.showHoldingsFor();
  }

  ShowNextPage() {
    this.pageIndex++;
    this.showHoldingsFor();
  }

  ShowLastPage() {
    this.pageIndex = this.TotalPages;
    this.showHoldingsFor();
  }

}
