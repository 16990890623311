import { LogoutComponent } from './logout/logout.component';
import { Error404Component } from './error404/error404.component';
import { LoginComponent } from './login/login.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { QuickSearchComponent } from './quick-search/quick-search.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClassInfoComponent } from './class-info/class-info.component';
import { FundHoldingsComponent } from './fund-holdings/fund-holdings.component';
//import { FundSeriesMasterComponent } from './fund-series-master/fund-series-master.component';
import { FundDetailsComponent } from './fund-details/fund-details.component';
import { MsalGuard } from '@azure/msal-angular';


const routes: Routes = [
  //{ path: '', component: QuickSearchComponent, canActivate: [MsalGuard] },
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'classinfo', component: ClassInfoComponent, canActivate: [MsalGuard] },
  { path: 'holdings', component: FundHoldingsComponent, canActivate: [MsalGuard] },
  { path: 'holdings/q/:id', component: FundHoldingsComponent, canActivate: [MsalGuard] },
  { path: 'quick-search', component: QuickSearchComponent, canActivate: [MsalGuard] },
  //{ path: 'quick-search/loggedIn', component: QuickSearchComponent, canActivate: [MsalGuard] },
  { path: 'search-result', component: SearchResultComponent, canActivate: [MsalGuard] },
  { path: 'search-result/:cname', component: SearchResultComponent, canActivate: [MsalGuard] },
  { path: 'search-result/cat/:cname/:stype', component: SearchResultComponent, canActivate: [MsalGuard] },
  { path: 'fund-details/:cname/:id', component: FundDetailsComponent, canActivate: [MsalGuard] },
  { path: 'fund-details/:cname/:id/:hid', component: FundDetailsComponent, canActivate: [MsalGuard] },
  { path: 'not-found', component: Error404Component},
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
